<template>
    <div>
        <banner></banner>
        <div class="gongju-wrap w-1300">
            <!-- <div class="center-text">
                <p></p>
                <span>行业分类</span>
            </div> -->
            <div class="meiyong-text">
                <div class="meiyong-p">产业出海</div>
                <!-- <div class="meiyong-p1">产业出海新选择</div> -->
            </div>
            <!-- <div class="tuijian-list">
                <div class="tuijian-item" v-for="(item,index) in gongsiList" :key="index" >
                    <div class="tuijian-p">{{item.name}}</div>
                    <div class="tuijian-bt" @click="goTo('pinpai_details',{id:item.id,key:0})">查看详细</div>
                </div>
            </div> -->
            <div class="pinpai-list">
                <div class="tuijian-item"  v-for="(item,index) in gongsiList" :key="index"  @click="goUrl(item.link)">
                    <div class="pinpai-img1">
                        <img :src="item.pic_url" alt="">
                    </div>
                    <div class="pinpai-p">{{item.title}}</div>
                </div>
            </div>
            <div class="meiyong-text">
                <div class="meiyong-p">品牌出海</div>
                <!-- <div class="meiyong-p1">品牌出海新选择</div> -->
            </div>
            <div class="pinpai-list">
                <div class="tuijian-item"  v-for="(item,index) in pinpaiList" :key="index"  @click="goUrl(item.link)">
                    <div class="pinpai-img">
                        <img :src="item.pic_url" alt="">
                    </div>
                     <div class="pinpai-p">{{item.title}}</div>
                </div>
            </div>

            <div class="meiyong-wrap">
                <div class="meiyong-text">
                    <div class="meiyong-p">{{gg[0?gg[0].title:'']}}</div>
                    <div class="meiyong-p1">{{gg[0?gg[0].content:'']}}</div>
                </div>
                <div class="meiyong-img"><img :src="gg[0]?gg[0].pic_url:require('../../assets/images/gongjubj.png')" alt=""></div>                
            </div>

            <div class="meiyong-text">
                <div class="meiyong-p">行业分类</div>
                <div class="more" v-if="chanyeList && chanyeList.length>0"  @click="goTo('pinpai_list',{id:chanyeList[0].id})">更多分类 <i class="el-icon-arrow-right"></i></div>
                <!-- <div class="meiyong-p1">外贸营销平台工具外贸营销平台工具外贸营销平台工具</div> -->
            </div>
            <div class="chanye-list">
                <div class="tuijian-item"  v-for="(item,index) in chanyeList" :key="index"  @click="goTo('pinpai_list',{id:item.id})">
                    <div class="pinpai-img">
                        <img :src="item.banner_url" alt="">
                        <p>{{item.name}}</p>
                    </div>
                </div>
            </div>

            
        </div>

        
        

        <div class="jieshao-wrap">
            <img :src="gg[1]?gg[1].pic_url:require('../../assets/images/gongjubj.png')" alt="">
            <!-- <div class="jiehsao-img">
                <img src="../../assets/images/gongju.png" alt="">
            </div>
            <div class="jiehsao-jia"><i class="el-icon-plus"></i></div>
            <div class="jieshao">
                <div class="jieshao-item">
                    <div class="jieshao-text">服务产品，突破想象</div>
                    <div class="jieshao-p">专注于服务出口企业，打造N+1网络营销模式，取得真实询盘取得真实询盘</div>
                </div>
                <div class="jieshao-item">
                    <div class="jieshao-text">服务产品，突破想象</div>
                    <div class="jieshao-p">专注于服务出口企业，打造N+1网络营销模式，取得真实询盘取得真实询盘</div>
                </div>
            </div> -->
        </div>

        <div class="bottom-img">
            <img :src="gg[2]?gg[2].pic_url:require('../../assets/images/gongju-bj.jpg')" alt="">
        </div>

    </div>
</template>

<script>
    import banner from '../../components/banner'
    import {getplatexts,getplates,getplateAds} from '../../api/index'
    export default {
        data() {
            return {
                gongsiList:[],
                pinpaiList:[],
                chanyeList:[],
                gg:[]
            }
        },
        components:{
            banner
        },
        created() {
            this.getplatexts()
            this.getplateAds()
            this.getplateAds1()
            this.getplates()
        },
        methods:{
            // 跳转外链
            goUrl(url){
               window.open(url) 
            },
            // 跳转页面
            goTo(url,query) {
                this.$router.push({ 
                    path: url ,
                    query
                });
            },

            // 产业出海
            getplatexts(){
                getplateAds({
                    plate_id:20,
                    seat:-2
                }).then((res)=>{
                    console.log('产业出海',res)
                    this.gongsiList=res.data.slice(0,10);

                })
            },
            // 行业分类
            getplates(){
                getplates({
                    p_id:20
                }).then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.chanyeList=res.data.slice(0,10);
                    }
                })
            },
            // 品牌出海
            getplateAds(){
                getplateAds({
                    plate_id:20
                }).then((res)=>{
                    // console.log(res)
                    this.pinpaiList=res.data

                })
            },
            // 广告
            getplateAds1(){
                getplateAds({
                    plate_id:20,
                    seat:0
                }).then((res)=>{
                    // console.log(res)
                    if(res.code==200){
                        this.gg=res.data
                    }
                })
            },
            
        }
        
    }
</script>

<style lang="less" scoped>
.gongju-wrap{
    margin: auto;
    .tuijian-list{
        margin-top: 40px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .tuijian-item{
            width: 18%;        
            background: #FFFFFF;
            box-sizing: border-box;
            box-shadow: 0px 0px 16px 5px rgba(59, 121, 204, 0.18);
            margin-right: 2.5%;
            margin-bottom: 35px;
            border-radius: 15px;
            padding: 0 14px;
            cursor: pointer;
            &:nth-child(5n+0){margin-right: 0;}            
            .tuijian-p{margin-top: 30px;color: #0071DB;font-size: 20px;font-weight: bold;text-align: center;padding-bottom: 20px;border-bottom: 1px solid #0071DB;}
            .tuijian-bt{
                width: 70%;height:30px;margin: 15px auto;
                background: #0071DB;border-radius: 14px;text-align: center;line-height: 30px;font-size: 14px;color: #fff;
            }
        }
    }

    .pinpai-list{
        margin-top: 40px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .tuijian-item{
            width: 18%;        
            background: #FFFFFF;
            box-sizing: border-box;
            border: 1px solid #f1f1f1;
            margin-right: 2.5%;
            margin-bottom: 35px;
            border-radius: 2px;
            padding:20px 14px;
            cursor: pointer;
            &:hover{box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.05);}
            &:nth-child(5n+0){margin-right: 0;}            
            .pinpai-img{
                width: 100%;
                height: 60px;
                img{width: 100%;height: 100%;object-fit: cover;}
            }
            .pinpai-img1{
                width: 100%;
                height: 120px;
                img{width: 100%;height: 100%;object-fit: cover;}
            }
            .pinpai-p{
                margin-top: 15px;
                text-align: center;
                font-size: 16px;
            }
            
        }
    }
    .chanye-list{
        margin-top: 40px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .tuijian-item{
            width: 18%;        
            background: #FFFFFF;
            box-sizing: border-box;
            margin-right: 2.5%;
            margin-bottom: 35px;
            border-radius: 2px;
            // padding:20px 14px;
            cursor: pointer;
            // &:hover{box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.05);}
            &:nth-child(5n+0){margin-right: 0;}            
            .pinpai-img{
                width: 100%;
                // height: 130px;
                img{width: 100%;height: 130px;object-fit: cover;}
                p{text-align: center;margin-top: 10px;}
            }
            
        }
    }

    .meiyong-wrap{
        width: 94%;margin: auto;padding-bottom:25px;
        .meiyong-text{
            margin: 45px 0;text-align: center;position: relative;
            .meiyong-p{color: #0071DB;font-size: 43px;font-weight: bold;}
            .meiyong-p1{color: #262626;font-size: 16px;font-weight: 400;margin-top: 15px;}
            
        }
        .meiyong-img{
            width: 100%;
            margin-top: 82px;
            img{width: 100%;}    
        }
    }
}
.meiyong-text{
    margin: 45px 0;text-align: center;position: relative;
    .meiyong-p{color: #0071DB;font-size: 43px;font-weight: bold;}
    .meiyong-p1{color: #262626;font-size: 16px;font-weight: 400;margin-top: 15px;}
    .more{
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
        font-size: 18px;
        color: #ff7048;
        cursor: pointer;
    }
}


.jieshao-wrap{
    width: 1200px;
    padding: 40px 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img{width: 100%;display: flex;}
    .jiehsao-img{
        width: 569px;
        img{width: 100%;height: auto;}
    }
    .jiehsao-jia{
        width: 64px;
        height: 64px;
        background: #0071DB;
        border-radius: 50%;
        line-height: 64px;
        text-align: center;
        color: #fff;
        font-size: 45px;
    }
    .jieshao{
        .jieshao-item{
            width: 280px;
            padding: 35px 40px;
            background: #FFFFFF;
            box-shadow: 0px 0px 19px 2px rgba(28, 123, 192, 0.32);
            border-radius: 5px;
            margin: 50px 0;
            .jieshao-text{
                color: #222;
                font-size: 20px;
                font-weight: bold;
                padding-bottom: 10px;
            }
            .jieshao-p{
                color: #333333;
                font-size: 14px;
                font-weight: 300;
            }
        }
    }
}


.bottom-img{
    width: 100%;height: auto;
    img{width: 100%;height: auto;display: flex;}
}


</style>